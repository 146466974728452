.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite alternate 2s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.Background {
background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.Background2 {
background-color: #282c34;
  min-height: 100vh;
  display: flex;
align-items: center;
padding : 100px;
  flex-direction: column;
  color: white;
}

.OutsideBox {
  font-size: calc(10px + 1vmin);
background-color : white;
color : black;
width : 50%;
text-align : center;
border-radius: 25px;
border: 3px solid green;
box-shadow: 5px 5px 5px black;
padding-bottom : 30px;
margin-top : 20px;
}

.InsideBox{
padding-top: 30px;

}

.Content{
max-width : 50%;
padding : 30px;
}

.Content2{
padding : 30px;
}


.BoxLogo {
  background-color: white;
border-radius: 25px;
border: 3px solid black;
padding : 20px;
display: inline-block;
width: min-content;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.3);
  }
}

.puzzle-container {
  text-align: center;
  margin-top: 50px;
}

.letters-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #f7f7f7;
  max-width: 1000px;
  margin: 0 auto;
}

.letter {
  background-color: #8B9DFF;
  color: white;
  font-size: 24px;
  padding: 10px 15px;
  margin: 2px;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
}

.letter:hover {
  background-color: #3C14FC;
}

.Error{
	color : red;
	font-size : 1.5em;
	padding-bottom : 20px;
}